import { useStore } from "@/store";
import { IFileListItem } from "./file";
import { RequestKindTypes, RequestStatuses } from "./request";

export interface ISecondOpinionPurpose {
  doctorKey :  Number | null,
  other: string,
  files: Array<IFileListItem> | null
}

export interface IFromValidate{
  doctorKey: Boolean,
  other: Boolean
}

export class SecondOpinion{
  public PID:string = "";
  public FacilityKey:number | null = null;
  public RequestKey:number | null = null;
  public CultureLangName="";
  public RequestKind:RequestKindTypes | null = null;
  public RequestPurpose = "";
  public PurposeOthers = "";
  public ChiefComplaint = "";
  public DesiredDrKey: number | null = null;
  public DesiredDateTime1: string ="";
  public SaveMode = 0;
  public RequestStatus:RequestStatuses = 0;
  public PharmacyKey = 0;


  constructor(data:{ other:string, doctorkey:number, requestKey:number}){
    const accountInfo = useStore().getters.getAccountInfo;
    // const locale = useI18n().locale;
    if(!accountInfo) return;
    this.PID = accountInfo.PID;
    this.FacilityKey = accountInfo.FacilityKey;
    // this.CultureLangName = locale.value;
    this.RequestKind = RequestKindTypes.SecondOpinion;
    this.ChiefComplaint = data.other;    
    this.DesiredDrKey = data.doctorkey;
    this.RequestKey = data.requestKey;
    this.DesiredDateTime1 = '00000000000000';
    
  }
}

