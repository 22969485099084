
import { defineComponent, ref, reactive, watch, computed, Ref } from "vue";
import $API from "@/services";
import FileUploader from "@/components/common/FileUploader.vue";
import Textarea from "primevue/textarea";
import { AxiosResponse } from "axios";
import {
  IAlert,
  IResponseData,
  RouterName,
  StatusTexts,
} from "@/models/common";
import { IDoctor, PossibleTypes } from "@/models/doctor";
import { useStore } from "@/store";
import { CommonMutationTypes } from "@/store/modules/common/mutations";
import { IRequest, RequestStatuses, RequestKindTypes } from "@/models/request";
import { IFileListItem } from "@/models/file";
import FileList from "@/components/file/FileList.vue";
import { DataType, FileType } from "@/services/file";
import Sidebar from "primevue/sidebar";
import Doctors from "@/components/common/Doctors/Doctors.vue";
import {
  IFromValidate,
  ISecondOpinionPurpose,
  SecondOpinion,
} from "@/models/secondOpinion";
import router from "@/router";
import { Form } from "vee-validate";
import CustomConfirmDialog from "@/components/primevueCustom/ConfirmDialog.vue";
import { useI18n } from "vue-i18n";
import FileViewer from "@/components/file/FileViewer.vue";
import { messageType } from "@/models/message";
import PageFrame from "@/components/common/PageFrame.vue";
import RequestStep from "@/components/common/Request/RequestStep.vue";
import OverlayPanel from "primevue/overlaypanel";
import DoctorItem from "@/components/common/Doctors/DoctorItem.vue";
import Button from "primevue/button";
import { getDoctorList } from "@/services/doctor";
import { useConfirm } from "primevue/useconfirm";

enum PageStates {
  "DOCTOR",
  "PURPOSE",
}

export default defineComponent({
  name: "Second Opinion Request",
  props: {
    id: String,
    isSave: Number,
  },
  setup(props) {
    const state = reactive({
      id: props.id,
      page: !(props.id && Number.parseInt(props.id))
        ? PageStates.DOCTOR
        : PageStates.PURPOSE,
      purpose: {
        doctorKey: null as Number | null,
        other: "",
        files: null as Array<IFileListItem> | null,
      } as ISecondOpinionPurpose,
      requestStatus: null as RequestStatuses | null,
      selectFile: null as IFileListItem | null,
      visibleFull: false,
      isLoading: true,
      message: "",
    });

    const store = useStore();

    const doctors: Ref<IDoctor> = ref({} as IDoctor);

    const formValidate: IFromValidate = reactive({
      doctorKey: false,
      other: false,
    });

    const confirm = useConfirm();
    const isConfirmButton = () => {
      confirm.require({
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "확인",
        group: "confirm",
        accept: () => {
          state.page = PageStates.DOCTOR;
          goBack();
        },
        reject: () => {
          state.page = PageStates.DOCTOR;
          goBack();
        },
      });
    };

    const op$: Ref<OverlayPanel | null> = ref(null);

    const selectedDoctor = computed(() =>
      !!doctors.value && !!doctors.value?.selectedDoctor
        ? doctors.value.selectedDoctor[0]
        : ""
    );

    watch(
      () => state.purpose,
      (n: ISecondOpinionPurpose) => {
        (formValidate.doctorKey = !!n.doctorKey),
          (formValidate.other = !!n.other);
      },
      { deep: true }
    );

    const url = computed(() => {
      return `${$API.BaseURL}/api/ApiFileUploadV2/Upload?reftab=request&refkey=${state.id}`;
    });

    const filesCount = computed(() => {
      return state.purpose.files?.filter(
        (f) => f.PrescriptionFlag !== 1 && f.FileStatus === 0
      ).length;
    });

    const isEditable = computed(() => {
      return (
        !state.id ||
        ((state.requestStatus ?? 100) >= RequestStatuses.Preparing &&
          (state.requestStatus ?? 100) < RequestStatuses.Scheduled)
      );
    });

    const { t } = useI18n({ useScope: "global" });

    const alert: Ref<IAlert> = ref({
      type: messageType.ERROR,
      content: "",
    });

    const totalPages = computed(() => Object.keys(PageStates).length / 2);

    const nextPage = (e: PageStates) => {
      state.page = e;
    };
    const prevPage = (e: PageStates) => {
      state.page = e;
    };
    const descMore = ref("");
    const opToggle = (event: any, profile: string) => {
      event?.preventDefault();
      descMore.value = profile;
      op$.value?.toggle(event);
    };

    const doctorKeyValidate = computed(() => formValidate.doctorKey);
    const otherValidate = computed(() => formValidate.other);
    const isValidate = computed(() => {
      if (state.page === PageStates.DOCTOR) return doctorKeyValidate.value;
      else return doctorKeyValidate.value && otherValidate.value;
    });

    const next = () => {
      state.page += 1;
    };
    const prev = () => {
      state.page -= 1;
    };

    const goBack = () => {
      router.go(-1);
    };

    return {
      state,
      props,
      PageStates,
      store,
      DataType,
      url,
      isEditable,
      formValidate,
      FileType,
      alert,
      t,
      filesCount,
      RequestKindTypes,
      PossibleTypes,
      totalPages,
      nextPage,
      prevPage,
      doctors,
      op$,
      opToggle,
      selectedDoctor,
      descMore,
      doctorKeyValidate,
      otherValidate,
      isValidate,
      next,
      prev,
      isConfirmButton,
    };
  },
  async mounted() {
    await this.onSetData();
  },
  methods: {
    async saveConfirm() {
      this.$confirm.require({
        header: this.t("ReceptionSaveHeader"),
        acceptLabel: this.t("Yes"),
        rejectLabel: this.t("No"),
        group: "save",
        accept: async () => {
          //callback to execute when user confirms the action
          await this.onSave();
        },
        reject: () => {
          //callback to execute when user rejects the action
        },
      });
    },

    async deletedFile() {
      await this.onSetData();
    },
    filesProgress() {
      if (!this.store.getters.getIsFileUpdate) {
        setTimeout(() => {
          this.isConfirmButton();
        }, 1000);
      }
    },
    async onSetData() {
      try {
        this.state.isLoading = true;
        this.store.commit(CommonMutationTypes.SET_IS_LODING, true);

        this.doctors.doctorList = (
          (await getDoctorList(PossibleTypes.SECOND_OPINION)) as IDoctor
        ).doctorList;
        this.doctors.doctorListSplit = [this.doctors.doctorList];
        this.doctors.selectedPage = 0;
        this.doctors.displayDoctorRow = 0;

        await Promise.all(
          (this.doctors.selectedDoctor = this.doctors.doctorList.filter((x) => {
            if (x.DoctorKey === this.state.purpose.doctorKey) {
              return x;
            }
          }))
        );
        await this.getDoctorList();
      } catch (e) {
        console.error(e);
        this.store.commit(CommonMutationTypes.FILE_UPDATE, false);
      } finally {
        this.store.commit(
          CommonMutationTypes.SET_IS_LODING,
          this.store.getters.getIsFileUpdate
        );
        this.state.isLoading = false;
      }
    },

    routerReplace() {
      const id = this.state.id ?? 0;
      router.replace({
        name: RouterName.SECOND_OPINION_REQUEST_EDIT,
        params: { id },
      });
      this.onSetData();
    },

    saveValidate() {
      return this.formValidate.doctorKey && this.formValidate.other;
    },

    async onSave() {
      try {
        this.store.commit(CommonMutationTypes.SET_IS_LODING, true);
        if (!this.saveValidate()) {
          //this.state.message = "validateError";

          this.alert = {
            content: "validateError",
            type: messageType.ERROR,
          };

          this.store.commit(CommonMutationTypes.SET_IS_LODING, false);
          return;
        }

        const requestKey = !this.state.id ? 0 : Number.parseInt(this.state.id);
        const saveData = new SecondOpinion({
          doctorkey: this.state.purpose.doctorKey?.valueOf() ?? 0,
          other: this.state.purpose.other,
          requestKey,
        });

        const res: AxiosResponse = await $API.RequestService.save(saveData);
        if (res.statusText === StatusTexts.OK || res.status === 200) {
          this.state.id = res.data.ResultData.RequestKey;
          this.state.requestStatus = res.data.ResultData.RequestStatus;

          if ((this.$refs.fileUploader$ as any).getFilesCount() > 0)
            await (this.$refs.fileUploader$ as any).upload(this.url);
          else {
            // setTimeout(() => {
            this.store.commit(CommonMutationTypes.SET_IS_LODING, false);
            this.isConfirmButton();
            // }, 1000);
          }
        }
      } catch (e) {
        console.error(e);

        this.alert = {
          content: "저장 실패",
          type: messageType.ERROR,
        };
      } finally {
        this.store.commit(CommonMutationTypes.SET_IS_LODING, false);
      }
    },

    getImagePath(filePath: string) {
      return $API.FileService.getFilePath(filePath);
    },

    getPdfPath(filePath: string) {
      return $API.FileService.getPdfPath(filePath);
    },

    async onSelectedRow(selectedItem: IFileListItem) {
      try {
        this.state.selectFile = selectedItem;

        if (this.state.selectFile.DataType === DataType.DICOM)
          await this.getStudyKey(this.state.selectFile.FileKey.toString());

        this.state.visibleFull = true;
      } catch (e) {
        console.error(e);
      }
    },
    async getStudyKey(fileKey: string) {
      return await $API.FileService.getStudyKey(fileKey);
    },

    async selectDoctor(doctor: IDoctor) {
      this.doctors = doctor;
      this.state.purpose.doctorKey = doctor.selectedDoctor[0].DoctorKey;
    },

    submit(e: Event) {
      e.preventDefault();
      //(this.$refs.fileUpload as any).clear();
    },
    async getRequest(id: string) {
      const res: AxiosResponse<IResponseData<IRequest>> =
        await $API.RequestService.getRequest(Number.parseInt(id));
      const {
        data: { ResultData },
      } = res;
      if (!Array.isArray(ResultData)) {
        this.state.purpose.other =
          ResultData.ChiefComplaint?.toString() ??
          ResultData.PurposeOthers?.toString() ??
          "";
        this.state.requestStatus = ResultData.RequestStatus ?? null;
        await this.getRequestDoctor(ResultData);
      }
    },

    async getFiles(id: string) {
      const ResultData = await $API.FileService.getFile(id);
      if (!Array.isArray(ResultData)) return;
      this.state.purpose.files = ResultData;
    },

    async getRequestDoctor(resultData: IRequest) {
      this.state.purpose.doctorKey = resultData.DesiredDrKey;
    },
    async getDoctorList() {
      await (this.$refs?.doctors$ as any)?.onSetData();
    },
  },

  components: {
    FileUploader,
    Textarea,
    FileList,
    Sidebar,
    Doctors,
    Form,
    FileViewer,
    PageFrame,
    RequestStep,
    OverlayPanel,
    DoctorItem,
    Button,
    CustomConfirmDialog,
  },
});
